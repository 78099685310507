import Vue from 'vue'
import VueRouter from 'vue-router'
import { getToken } from '@/utils/auth'

import { isWeixin } from '@/utils/globalFun.js';
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'mainHome',
    component: () => import('@/layout/index'),
    redirect: '/transit',
    children: [
      {
        path: '/home',
        name: 'home',
        meta: {
          tabTitle: 'home',
        },
        icon: require('@/assets/menu/home.png'),
        activeIcon: require('@/assets/menu/home-active.png'),
        component: () => import('@/views/home/')
      },
      {
        path: '/order',
        name: 'Order',
        meta: {
          tabTitle: 'order',
        },
        icon: require('@/assets/menu/order.png'),
        activeIcon: require('@/assets/menu/order-active.png'),
        component: () => import('@/views/order/')
      },
      {
        path: '/my',
        name: 'my',
        meta: {
          tabTitle: 'my',
        },
        icon: require('@/assets/menu/my.png'),
        activeIcon: require('@/assets/menu/my-active.png'),
        component: () => import('@/views/my/')
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      tabTitle: 'login',
    },
    component: () => import('@/views/login/login')
  },
  {
    path: '/material',
    name: 'material',
    meta: {
      tabTitle: 'material',
    },
    component: () => import('@/views/material/index')
  },
  {
    path: '/reserve',
    name: 'reserve',
    meta: {
      tabTitle: 'reserve',
    },
    component: () => import('@/views/reserve/index')
  },
  {
    path: '/reserveDetail',
    name: 'reserveDetail',
    meta: {
      tabTitle: 'reserveDetail',
    },
    component: () => import('@/views/reserveDetail/index')
  },
  {
    path: '/appointmentTime',
    name: 'appointmentTime',
    meta: {
      tabTitle: 'appointmentTime',
    },
    component: () => import('@/views/appointmentTime/index')
  },
  {
    path: '/submitAppointment',
    name: 'submitAppointment',
    meta: {
      tabTitle: 'submitAppointment',
    },
    component: () => import('@/views/submitAppointment/index')
  },
  {
    path: '/clinicInfo',
    name: 'clinicInfo',
    meta: {
      tabTitle: 'clinicInfo',
    },
    component: () => import('@/views/clinicInfo/index')
  },
  {
    path: '/clinicInfoDetail',
    name: 'clinicInfoDetail',
    meta: {
      tabTitle: 'clinicInfoDetail',
    },
    component: () => import('@/views/clinicInfoDetail/index')
  },
  {
    path: '/submitOrder',
    name: 'submitOrder',
    meta: {
      tabTitle: 'submitOrder',
    },
    component: () => import('@/views/submitOrder/index')
  },
  {
    path: '/updateMy',
    name: 'updateMy',
    meta: {
      tabTitle: 'updateMy',
    },
    component: () => import('@/views/updateMy/index')
  },
  {
    path: '/topicCAN',
    name: 'topicCAN',
    meta: {
      tabTitle: 'topicCAN',
    },
    component: () => import('@/views/questionnaire/topicCAN')
  },
  {
    path: '/topicAUS',
    name: 'topicAUS',
    meta: {
      tabTitle: 'topicAUS',
    },
    component: () => import('@/views/questionnaire/topicAUS')
  },
  {
    path: '/topicNZ',
    name: 'topicNZ',
    meta: {
      tabTitle: 'topicNZ',
    },
    component: () => import('@/views/questionnaire/topicNZ')
  },
  {
    path: '/topicKR',
    name: 'topicKR',
    meta: {
      tabTitle: 'topicKR',
    },
    component: () => import('@/views/questionnaire/topicKR')
  },
  {
    path: '/topicUK',
    name: 'topicUK',
    meta: {
      tabTitle: 'topicUK',
    },
    component: () => import('@/views/questionnaire/topicUK')
  },
  {
    path: '/sign',
    name: 'sign',
    meta: {
      tabTitle: 'sign',
    },
    component: () => import('@/views/sign/index')
  },
  {
    path: '/noticeDetail',
    name: 'noticeDetail',
    meta: {
      tabTitle: 'noticeDetail',
    },
    component: () => import('@/views/notice/noticeDetail')
  },
  {
    path: '/noticeList',
    name: 'noticeList',
    meta: {
      tabTitle: 'noticeList',
    },
    component: () => import('@/views/notice/noticeList')
  },
  {
    path: '/transit',
    name: 'transit',
    meta: {
      tabTitle: 'transit',
    },
    component: () => import('@/views/transit/index')
  },
  {
    path: '/invoicing',
    name: 'invoicing',
    meta: {
      tabTitle: 'invoicing',
    },
    component: () => import('@/views/invoicing/index')
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes: routes
})
// const white = ['/login', '/home', '/my', '/order', '/reserve', '/material', '/appointmentTime']
const white = ['/login', '/home', '/transit']
// 白名单过滤
router.beforeEach((to, from, next) => {
  if (white.indexOf(to.path) >= 0) {
    next()
  } else {
    const token = getToken()
    if (token) {
      next()
    } else {
      const isWeixinStatus = isWeixin();
      if (!isWeixinStatus) {
        router.push('/login')
        return
      }
      next()
    }
  }
})

export default router
