const TokenKey = 'token'
const userInfoKey = 'userInfo'

export function getUserInfo () {
  return localStorage.getItem(userInfoKey)
}
export function setUserInfo (userInfo) {
  return localStorage.setItem(userInfoKey, userInfo)
}

export function getToken () {
  return localStorage.getItem(TokenKey)
}

export function setToken (token) {
  return localStorage.setItem(TokenKey, token)
}

export function removeToken () {
  return localStorage.setItem(TokenKey, '')
}

