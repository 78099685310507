import Cookies from 'js-cookie'

const state = {
  language: Cookies.get('language') || 'zh',
  weChatInfo: {},
  signUrl: ''
}

const mutations = {
  SET_LANGUAGE: (state, language) => {
    state.language = language
    Cookies.set('language', language)
  },
  SET_WECHATINFO: (state, weChatInfo) => {
    state.weChatInfo = weChatInfo
  },
  SET_RESERVEDATA: (state, value) => {
    state.signUrl = value;
  }
}

const actions = {
  setLanguage ({ commit }, language) {
    commit('SET_LANGUAGE', language)
  },
  setWeChatInfo ({ commit }, weChatInfo) {
    commit('SET_WECHATINFO', weChatInfo)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
