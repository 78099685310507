export default {
  projectName: 'UMP出国签证体检',
  route: {
    home: '主页',
    my: '我的',
    order: '我的订单',
    reserve: '预约体检',
    material: '资料'
  },
  login: {
    telephone: '手机号',
    password: '密码',
    requireTelephone: '请输入手机号码',
    requirePassword: '请输入密码',
    submit: '登录'
  }

}
