import request from '@/utils/request'

// 根据域名获取client信息
export function getClientByDomain (data) {
  return request({
    url: '/org/getClientByDomain',
    method: 'post',
    data
  })
}
// 登录
export function login (data) {
  return request({
    url: '/login',
    method: 'post',
    data
  })
}

// 获取登录二维码图片
export function loginImage (data) {
  return request({
    url: '/shop/loginImage',
    method: 'post',
    data
  })
}

// 获取当前登录人信息
export function getUser () {
  return request({
    url: '/user/getUser',
    method: 'get'
  })
}

// 根据域名获取公众号信息
export function getMpInfoByDomain (data) {
  return request({
    url: '/org/getWxSignature',
    method: 'post',
    data
  })
}
