export default {
  projectName: 'Visa',
  route: {
    home: 'Home',
    my: 'My',
    order: 'Order'
  },
  login: {
    telephone: 'Telephone',
    password: 'Password',
    requireTelephone: 'Please enter the phone number',
    requirePassword: 'Please enter the password',
    submit: 'Login In'
  }

}
