import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { Overlay } from 'vant';
import {
  DatePicker
} from 'element-ui';
import store from './store'
Vue.prototype['$store'] = store;
// global css
import '@/styles/index.scss'
import 'element-ui/lib/theme-chalk/index.css';

// 自定义全局方法
import * as utils from '@/utils/globalFun.js'

// 全局方法挂载
for (const i in utils) {
  Vue.prototype['$' + i] = utils[i];
}

Vue.config.productionTip = false

import Vant from 'vant';
import 'vant/lib/index.css';
// 引入模块后自动生效
import '@vant/touch-emulator';
// 手写签名
import signatureH5 from '@jensonhui/signature-h5';
import '@jensonhui/signature-h5/lib/signatureH5.css';

// 国际化
import i18n from './lang/index.js'

Vue.use(Vant, {
  i18n: (key, value) => i18n.t(key, value)
});
Vue.use(signatureH5);
Vue.use(Overlay);
Vue.use(DatePicker);
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

