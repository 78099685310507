// 初始化组件中data值
export function vueInitData (that, key = '') {
  let runStr = `Object.assign(that.$data,that.$options.data());`;
  if (typeof key === 'string' && key) {
    runStr = `that.${key} = that.$options.data().${key};`;
  }
  new Function('that', `${runStr};`)(that);
}

// 业务字典数据回显
export function dictShow (tempArr, cheArr, showKey = 'dictDataName') {
  !Array.isArray(cheArr) && (cheArr = [cheArr]);
  let result = '';
  for (const i in cheArr) {
    for (const j in tempArr) {
      if (cheArr[i] + '' === tempArr[j].dictDataValue + '') {
        result += tempArr[j][showKey] + '、';
      }
    }
  }
  return result.substring(0, result.length - 1);
}

import { listData } from '@/api/dict/dict.js';

// 字典缓存获取
export function getDictCache (params = {}, unshiftValue = []) {
  params = {
    'current': 0,
    'size': -1,
    enabledStatus: 0,
    ...params
  };

  if (!this.dictOption) {
    this.dictOption = {};
  }

  const cacheKey = JSON.stringify(params);
  const dictOption = this.$store.state.cache.dictData;
  if (dictOption[cacheKey] && dictOption[cacheKey].length > 0) {
    this.dictOption[params.dictTypeCode] = dictOption[cacheKey];
  } else {
    listData(params.dictTypeCode).then(res => {
      if (res && res.data && Array.isArray(res.data)) {
        const tempResult = JSON.parse(JSON.stringify(res.data));
        for (const i in unshiftValue) {
          tempResult.unshift(unshiftValue[i])
        }
        for (const i in tempResult) {
          tempResult[i].label = tempResult[i].dictDataName;
          tempResult[i].name = tempResult[i].dictDataName;
          tempResult[i].value = tempResult[i].dictDataValue;
        }
        this.dictOption[params.dictTypeCode] = tempResult;
        this.$store.commit('SET_DICT_DATA', { [cacheKey]: tempResult });
      } else {
        this.dictOption[params.dictTypeCode] = [];
      }
    })
  }
}

export function getDateTimeStr (dateTimeStamp) {
  // 获取年月日时分秒
  var date = new Date(dateTimeStamp);

  // 将年月日时分秒转换为时间戳
  var timestamp = date.getTime();
  var result = '';
  var minute = 1000 * 60; // 把分，时，天，周，半个月，一个月用毫秒表示
  var hour = minute * 60;
  var day = hour * 24;
  var week = day * 7;
  var halfamonth = day * 15;
  var month = day * 30;
  var year = month * 12;
  var now = new Date().getTime(); // 获取当前时间毫秒
  var diffValue = now - date;// 时间差

  if (diffValue < 0) {
    return;
  }
  var minC = diffValue / minute; // 计算时间差的分，时，天，周，月,年
  var hourC = diffValue / hour;
  var dayC = diffValue / day;
  var weekC = diffValue / week;
  var monthC = diffValue / month;
  var yearC = diffValue / year;
  if (yearC >= 1) {
    result = ' ' + parseInt(yearC) + '年前'
  } else if (monthC >= 1 && monthC <= 3) {
    result = ' ' + parseInt(monthC) + '月前'
  } else if (weekC >= 1 && weekC <= 3) {
    result = ' ' + parseInt(weekC) + '周前'
  } else if (dayC >= 1 && dayC <= 6) {
    result = ' ' + parseInt(dayC) + '天前'
  } else if (hourC >= 1 && hourC <= 23) {
    result = ' ' + parseInt(hourC) + '小时前'
  } else if (minC >= 1 && minC <= 59) {
    result = '刚刚'
  } else if (diffValue >= 0 && diffValue <= minute) {
    result = '刚刚'
  } else {
    var datetime = new Date();
    datetime.setTime(dateTimeStamp);
    var Nyear = datetime.getFullYear();
    var Nmonth = datetime.getMonth() + 1 < 10 ? '0' + (datetime.getMonth() + 1) : datetime.getMonth() + 1;
    var Ndate = datetime.getDate() < 10 ? '0' + datetime.getDate() : datetime.getDate();
    var Nhour = datetime.getHours() < 10 ? '0' + datetime.getHours() : datetime.getHours();
    var Nminute = datetime.getMinutes() < 10 ? '0' + datetime.getMinutes() : datetime.getMinutes();
    var Nsecond = datetime.getSeconds() < 10 ? '0' + datetime.getSeconds() : datetime.getSeconds();
    result = Nyear + '-' + Nmonth + '-' + Ndate
  }
  return result;
}

// 前端获取url参数方法
export function getUrlParams () {
  var params = {};
  var query = window.location.href.replace(/.*\?/, '');
  /http/.test(query) && (query = '');
  var tempVar = query ? query.split('&') : [];
  for (var k in tempVar) {
    params[tempVar[k].split('=')[0]] = tempVar[k].split('=')[1];
  }
  return params;
}

export function getUserInfo () {
  const userInfoStr = this.$store.state.user.userInfo;
  return JSON.parse(userInfoStr);
}

// 判断用户是否在微信浏览器中打开h5
export function isWeixin () {
  const ua = window.navigator.userAgent.toLowerCase();
  if (ua.indexOf('micromessenger') !== -1) {
    return true;
  } else {
    return false;
  }
}
// 返回参数统一处理
export function RES (value, result = { data: 'data', code: 'code' }) {
  if (value[result.code] !== '0') {
    return null;
  }
  if (value[result.data] === '') {
    return null;
  }
  if (value[result.data] === null) {
    return null;
  }
  if (value[result.data] === undefined) {
    return null;
  }
  if (JSON.stringify(value[result.data]) === '{}') {
    return null;
  }
  if (JSON.stringify(value[result.data]) === '[]') {
    return null;
  }
  return value[result.data];
}

// 年龄计算
export function getAge(birthday, lastDay = null) {
  if (typeof birthday === "string" && /^(\d+-\d+-\d+)/.test(birthday)) {
    if (typeof lastDay !== "string" || !/^(\d+-\d+-\d+)/.test(lastDay)) {
      let d = new Date();
      lastDay = `${d.getFullYear()}-${(d.getMonth() + 1) <= 9 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)}-${d.getDate() <= 9 ? '0' + d.getDate() : d.getDate()}`
    }
    try {
      birthday = birthday.replace(/\s.*/g, '');
      lastDay = lastDay.replace(/\s.*/g, '');
      birthday = birthday.split('-');
      lastDay = lastDay.split('-');
      if (lastDay[0] - birthday[0] <= 0) {
        return 0;
      }
      let diffBirthday = Number(birthday[1]) + '' + birthday[2];
      let diffLastDay = Number(lastDay[1]) + '' + lastDay[2];
      if (Number(diffBirthday) <= Number(diffLastDay)) {
        return lastDay[0] - birthday[0];
      }
      return (lastDay[0] - birthday[0]) - 1;
    } catch (error) {
      return 0;
    }
  }
  return 0;
}

// 通过时间戳转换为日期格式
// console.log(timestampToDate(new Date().getTime()))
// console.log(timestampToDate(new Date().getTime(), (v) => { return v.Y + '年' + v.M + '月' + v.D + '日' + ' ' + v.h + '时' + v.m + '分' + v.s + '秒' }))
export function timestampToDate (timestamp, formatFun = null) {
  if ((timestamp + '').length === 10) {
    timestamp = timestamp * 1000;
  }
  const date = new Date(timestamp);
  const result = {};
  result.Y = (date.getFullYear() + '');
  result.M = completion(date.getMonth() + 1);
  result.D = completion(date.getDate());
  result.h = completion(date.getHours());
  result.m = completion(date.getMinutes());
  result.s = completion(date.getSeconds());
  function completion (num) {
    num = num.toString();
    return num[1] ? num : '0' + num;
  }
  if (formatFun == null) {
    return result.Y + '-' + result.M + '-' + result.D + ' ' + result.h + ':' + result.m + ':' + result.s;
  }
  return formatFun(result)
}

// 设置页面缓存 console.log(setPageCache('test',{name:'张三'}))
export function setPageCache (page, value) {
  let result = {}
  if (typeof page !== 'string') {
    page = JSON.stringify(page)
  }
  try {
    result = JSON.parse(sessionStorage[page]);
  } catch (error) {
    result = '';
  }
  if (value && result && typeof value === 'object' && typeof result === 'object') {
    for (const i in value) {
      result[i] = value[i];
    }
  } else {
    result = value || '';
  }
  sessionStorage[page] = JSON.stringify(result);
  return true;
}

// 获取页面缓存 console.log(getPageCache('test'))
export function getPageCache (page) {
  if (typeof page !== 'string') {
    page = JSON.stringify(page)
  }
  let result = {};
  try {
    result = JSON.parse(sessionStorage[page]);
  } catch (error) {
    result = '';
  }
  return result;
}

import { saveTrack } from '@/api/track.js';

// 新增锚点信息 eventType,businessIds,trackProperties
export function saveTrackInfo(trackinfo) {
  saveTrack(trackinfo).then(res => {})
}
