import Vue from 'vue'
import axios from 'axios'

import store from '@/store'
import router from '@/router'
import {
  getToken, removeToken
} from '@/utils/auth'
import { Toast } from 'vant';
import { isWeixin } from '@/utils/globalFun.js'

const baseURL = process.env.VUE_APP_DOMAIN_PREFIX
// create an axios instance
Vue.prototype.axios = axios
axios.defaults.timeout = 30000
const service = axios.create({
  baseURL,
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000 // request timeout
})
axios.defaults.headers['Content-Type'] = 'application/json'
// request interceptor
service.interceptors.request.use(
  config => {
    //
    if (store.getters.token) {
      config.headers['Authorization'] = 'Bearer ' + getToken();
    }
    const language = store.getters.language;
    if (language) {
      if (language === 'zh') {
        config.headers['language'] = 'zh_CN';
      }
      if (language === 'en') {
        config.headers['language'] = 'en_US';
      }
    }

    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    if (response.config.responseType === 'blob') {
      return response.data
    }
    if (res.code !== '0') {
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      /**
        token为空 20003
        public static final String TOKEN_IS_NULL = "20003";
        token认证失败
        public static final String TOKEN_APPROVE_ERROR = "20004";
        请先登录
        public static final String USER_NOT_LOGIN = "20005";
       */
      const tokenerr = ['3000', '3001', '3002', '3003', '3004', '3005', '3006', '3010']
      if (tokenerr.includes(res.code)) {
        if (window.location.href.indexOf('/transit') === -1) {
          store.dispatch('user/logout');
          sessionStorage.clear();
          localStorage.clear();
          router.push({ path: '/transit', query: {}});
          return;
        }
      } else {
        Toast({
          message: res.msg || 'Error',
          type: 'fail',
          duration: 4 * 1000
        })
      }
      return Promise.reject(new Error(res.msg || 'Error'))
    } else {
      return res
    }
  },
  error => {
    if (!error.message.includes('timeout')) {
      /* Message({
        message: '服务器暂无响应，请稍后重试',
        type: 'error',
        duration: 5 * 1000
      })*/
    }
    return Promise.reject(error)
  }
)

export default service
