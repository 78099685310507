import request from '@/utils/request'

// 查询字典数据列表
export function listData (query) {
  return request({
    url: '/common/dict/getListDictData/' + query,
    method: 'get',
    data: {}
  })
}
