
const cache = {
  state: {
    dictData: {},
  },
  mutations: {
    SET_DICT_DATA (state, value) {
      for (const i in value) {
        state.dictData[i] = value[i];
      }
    }
  },
  actions: {

  }
}

export default cache;
