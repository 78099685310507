import request from '@/utils/request'

// 新增锚点信息
export function saveTrack(data) {
  return request({
    url: '/track/saveTrack',
    method: 'post',
    data
  })
}
