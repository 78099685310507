import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Cookies from 'js-cookie'
import vantEnLocale from 'vant/es/locale/lang/en-US' // vant-ui lang
import vantZhLocale from 'vant/es/locale/lang/zh-CN'// vant-ui lang
import enLocale from './en.js'
import zhLocale from './zh.js'

Vue.use(VueI18n)

const messages = {
  en: {
    ...enLocale,
    ...vantEnLocale
  },
  zh: {
    ...zhLocale,
    ...vantZhLocale
  }
}

const i18n = new VueI18n({
  // set locale
  // options: en | zh | es
  locale: Cookies.get('language') || 'zh',
  // set locale messages
  messages
})

export default i18n
