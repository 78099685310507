<template>
  <div
    id="app"
    class="main-app"
  >
    <router-view :key="$route.path + $route.query.t" />
    <!-- <div id="lang-toggle">
      <button @click="toggleLanguage">{{ currentLang }}</button>
    </div> -->
  </div>
</template>

<script>
import { Toast } from 'vant';
import { getToken } from '@/utils/auth';
import { getClientByDomain, login } from '@/api/login/login';

export default {
  data () {
    return {
      isLoading: false,
      currentLang: 'zh',
      loginParam: {
        authorizedGrantTypes: 'mp',
        clientId: '',
        code: '',
        scope: 'mp',
      },
    };
  },
  created () {
    this.currentLang = this.$store.getters.language;
    // 获取项目的appid
    this.clientByDomain();
    // 设置项目的名称走国际化
    document.title = this.$t('projectName');
  },
  methods: {
    // 微信登录
    async weChatLogin () {
      const isWeChat = this.$isWeixin();
      var token = getToken();
      // 登录了，查看手机号码是否为空
      if (token) {
        console.log(this.$getUserInfo(), 'this.$getUserInfo()');
      }

      if (isWeChat && !token) {
        const getUrlParams = this.$getUrlParams();
        // code存在 说明已经请求微信了，则请求后台拿到token
        if (getUrlParams.code) {
          // 执行登录接口
          this.loginParam.code = getUrlParams.code;
          this.loginParam.clientId = this.$store.getters.weChatInfo.clientId;
          this.loginParam.companyCode = this.$store.getters.weChatInfo.companyCode;
          if (window['loginStatus']) {
            return;
          }
          window['loginStatus'] = true;
          const res = await login(this.loginParam);
          if (res.code === '0' && res.data) {
            await this.$store.dispatch('user/login', res.data)
            window.location.reload();
          }
          window['loginStatus'] = undefined;
          return;
        }
        if (this.$store.getters.weChatInfo?.wechatMpAppid && this.$store.getters.weChatInfo?.webServerRedirectUri) {
          // 现在需要请求微信拿到code
          const weChatUrl = process.env.VUE_APP_WECHAT_URL;
          const newWeChatUrl = weChatUrl
            .replaceAll('#{appId}', this.$store.getters.weChatInfo.wechatMpAppid)
            .replaceAll(
              '#{redirectUri}',
              this.$store.getters.weChatInfo.webServerRedirectUri
            );
          window.location.href = newWeChatUrl;
          return;
        }
      }
    },
    // 切换语言-换项目名称
    toggleLanguage () {
      if (this.currentLang === 'zh') {
        this.currentLang = 'en';
        this.$i18n.locale = 'en';
        this.$store.dispatch('app/setLanguage', 'en');
        document.title = this.$t('projectName');
        // 切换到英文
      } else {
        this.currentLang = 'zh';
        this.$i18n.locale = 'zh';
        // 切回中文
        this.$store.dispatch('app/setLanguage', 'zh');
        document.title = this.$t('projectName');
      }
    },
    async clientByDomain () {
      const domain = window.location.origin + window.location.pathname + ',';
      const param = {
        domain,
      };
      const res = await getClientByDomain(param);
      if (res.code === '0' && res.data) {
        const wechatParam = {
          wechatMpAppid: res.data.wechatMpAppid,
          webServerRedirectUri: res.data.webServerRedirectUri,
          clientId: res.data.clientId,
        };
        await this.$store.dispatch('app/setWeChatInfo', wechatParam);
        // 执行登录
        // await this.weChatLogin();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#lang-toggle {
  position: fixed;
  right: 18px;
  bottom: 60px;
}

.main-app {
  background: #F7F7F7;
  font-size: 14px;
  color: #333333;
}
</style>
