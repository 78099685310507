// import { login, logout, resetPassword } from '@/api/user'
import {
  getToken, getUserInfo,
  removeToken,
  setToken, setUserInfo
} from '@/utils/auth'
import Cookies from 'js-cookie';

const getDefaultState = () => {
  return {
    token: getToken(),
    userInfo: getUserInfo()
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_USERINFO: (state, userInfo) => {
    setUserInfo(JSON.stringify(userInfo));
    state.userInfo = JSON.stringify(userInfo);
  }
}

const actions = {
  // user login
  login ({ commit }, userInfo) {
    const { userInfoRes, accessToken } = userInfo
    return new Promise((resolve, reject) => {
      setToken(accessToken)
      commit('SET_TOKEN', accessToken)
      commit('SET_USERINFO', userInfoRes)
      resolve()
    })
  },

  // user logout
  logout ({ commit, state }) {
    return new Promise((resolve, reject) => {
      localStorage.clear();
      Cookies.clearAll()
      commit('RESET_STATE')
      resolve()
    })
  },

  // remove token
  resetToken ({ commit }) {
    return new Promise(resolve => {
      removeToken() // must remove  token  first
      commit('RESET_STATE')
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

